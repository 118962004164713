import React from 'react';
import { Link } from 'gatsby';

import 'components/style.scss';
import './discuss-project.scss';

const DiscussProject = ({ nextText, nextUrl }) => {
  return (
    <div style={{ paddingBottom: '300px' }}>
      <div class="is-pulled-left margin-bottom-3" style={{ width: '100%' }}>
        <p className="title is-7 is-family-secondary">Have a project that you'd like to discuss?</p>
      </div>
      <div className="is-hidden-mobile">
        <div class="is-pulled-left is-flex is-paddingless add-margin" style={{ alignItems: 'center' }}>
          <a className="button is-primary button-height-fix" href="mailto:hello@big62.com">
            <p className="title is-7 is-family-secondary" style={{ color: '#fff', transform: 'translateY(4px)' }}>
              YES!
            </p>
          </a>
          <Link className="inverted-button button-height-fix" to={nextUrl}>
            <p className="title is-7 is-family-secondary" style={{ transform: 'translateY(4px)' }}>
              {nextText ? nextText : 'NEXT PROJECT >'}
            </p>
          </Link>
        </div>
      </div>
      <div className="is-hidden-tablet">
        <div className="margin-bottom-2">
          <a className="button is-primary" href="mailto:hello@big62.com">
            <p className="title is-7 is-family-secondary" style={{ color: '#fff', transform: 'translateY(4px)' }}>
              YES!
            </p>
          </a>
        </div>
        <div>
          <Link className="inverted-link" to={nextUrl}>
            <p className="title is-7 is-family-secondary" style={{ transform: 'translateY(4px)' }}>
              {nextText ? nextText : 'NEXT PROJECT >'}
            </p>
          </Link>
        </div>
      </div>
      {/* Fix height of div with floating children*/}
      <div style={{ clear: 'both' }} />
      <span className="big62Text" style={{ paddingTop: '150px' }}>
        ©BIG62 Pty Ltd{' '}
      </span>
    </div>
  );
};

export default DiscussProject;
