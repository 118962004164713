import React from 'react';
import { Link } from 'gatsby';

import 'components/style.scss';
import './featured-work.scss';

const FeaturedWork = ({ src, alt }) => {
  return (
    <div className="columns is-vcentered is-desktop">
      <div className="column is-narrow">
        <Link className="yellow-button" activeClassName="yellow-button-active" to="/work/ivebi">
          <p className="is-featured-small-text" style={{ transform: 'translateY(2px)' }}>
            01.IVEBI
          </p>
        </Link>
      </div>
      <div className="column is-narrow">
        <Link className="yellow-button" activeClassName="yellow-button-active" to="/work/projectlex">
          <p className="is-featured-small-text" style={{ transform: 'translateY(2px)' }}>
            02.PROJECT LEX
          </p>
        </Link>
      </div>
      <div className="column is-narrow">
        <Link className="yellow-button" activeClassName="yellow-button-active" to="/work/humantrak">
          <p className="is-featured-small-text" style={{ transform: 'translateY(2px)' }}>
            03.HUMANTRAK
          </p>
        </Link>
      </div>
      <div className="column is-narrow">
        <Link className="yellow-button" activeClassName="yellow-button-active" to="/work/santostour">
          <p className="is-featured-small-text" style={{ transform: 'translateY(2px)' }}>
            04.SANTOS TOUR
          </p>
        </Link>
      </div>
    </div>
  );
};

export default FeaturedWork;
