import React from 'react';
import DiscussProject from 'components/parts/discuss-project';
import FeaturedWork from 'components/parts/featured-work';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import 'components/style.scss';

const LayoutHumantrak = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      screenshot1: file(relativePath: { eq: "humantrak/screenshot1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenshot2: file(relativePath: { eq: "humantrak/screenshot2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenshot3: file(relativePath: { eq: "humantrak/screenshot3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="big62-section">
      <div className="big62-container">
        <h2 className="title is-7 is-family-secondary">FEATURED WORK</h2>
        <div className="margin-top-3 margin-bottom-5">
          <FeaturedWork />
        </div>
        <div className="margin-top-3 margin-bottom-3">
          <h1 className="title is-main-title is-family-secondary">
            World's first 3d camera and IMU-combined motion camera and{' '}
            <span className="has-text-big62pink">analysis system.</span>
          </h1>
        </div>
        <div className="box embed-container">
          <iframe src="https://youtube.com/embed/VPrtO_QLiuk" frameborder="0" allowfullscreen></iframe>
        </div>
        <div className="margin-bottom-2">
          <br />
          <br />
          <h2 className="title is-7 is-family-secondary">OVERVIEW</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            HumanTrak is an innovative movement analysis system, that combines hardware and software to allow clinicians
            to analyse a patient's movement in realtime. HumanTrak uses its unique IMU (Inertial Measurement Unit) and
            camera-integrated system to record and analyse a range of common movement assessments, tests and exercises.
            The test results are immediately visible on screen during testing with a detailed PDF report provided
            afterwards.
            <br />
            <br />
            We came onboard initially to <span className="has-text-big62pink">develop the Windows application</span> in
            which clients could login to the system, create and manage patients, launch functional testing sessions and
            view session history at a glance.
            <br />
            <br />
            As the project continued we took on more reponsibilities including{' '}
            <span className="has-text-big62pink">Microsoft Azure cloud management</span>,{' '}
            <span className="has-text-big62pink">ASP.Net development</span>,{' '}
            <span className="has-text-big62pink">Unity3D development</span> and built the{' '}
            <span className="has-text-big62pink">PDF report generation system</span> from the ground up.
          </p>
          <br />
        </div>

        <Img className="bordered-image" fluid={data.screenshot1.childImageSharp.fluid} alt="Screenshot 1" />
        <br />
        <br />
        <Img className="bordered-image" fluid={data.screenshot2.childImageSharp.fluid} alt="Screenshot 2" />
        <br />
        <br />
        <Img className="bordered-image" fluid={data.screenshot3.childImageSharp.fluid} alt="Screenshot 3" />
        <br />
        <br />

        <div className="margin-bottom-2">
          <h2 className="title is-7 is-family-secondary">OUTCOME</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            One of our greatests successes was implementing the report generation system. The test session software
            provides test data including images. Working in collaboration with Vald engineers we were able to directly
            capture Kinect video images in real-time at key points during a patient's test for use in reporting. This
            data was formatted and applied to HTML templates which were then combined and finally converted into a PDF
            file for the client to consume.
          </p>
        </div>
        <div className="margin-top-4">
          <p className="is-body-small-text fix-spacing">
            We enjoyed working alongside Vald Performance. We were able to help provide value to their customers using
            our wide-ranging development experience.
          </p>
        </div>
        <br />
        <div className="big62-section" className="margin-top-4">
          <DiscussProject nextUrl="/work/santostour" />
        </div>
      </div>
    </div>
  );
};

export default LayoutHumantrak;
